<template>
<!-- Modal traspasar chat -->
<div class="modal fade" id="traspasarChat" tabindex="-1" role="dialog" aria-labelledby="Traspasar paciente a compañero" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 v-if="this.tipo==1" class="modal-title">Traspaso de chat masivo</h5>
                <h5 v-else-if="this.tipo==0" class="modal-title" >Traspaso de chat</h5>
                <h5 v-else-if="this.tipo==2" class="modal-title" >Asignar conversación</h5>
                <h5 v-else-if="this.tipo==4" class="modal-title" >Monitorear</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <h5 v-if="this.tipo==1" class="mb-3">Seleccione el asesor al que se le hará la transferencia y la etiqueta deseada.</h5>
                <h5 v-else-if="this.tipo==0" class="mb-3">Seleccione el asesor al que se le va a transferir el paciente.</h5>
                <h5 v-else-if="this.tipo==2" class="mb-3" >Seleccione COLA o al asesor para asignar paciente.</h5>
                <div v-if="this.alert1" class="alert alert-danger alert-dismissible fade show m-2 mt-3" role="alert">
                    Seleccionar asesor
                    <button @click="cerrar" type="button" class="close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div v-if="tipo==4" style="width:400px" class="d-flex flex-column">
                    <p v-if="sesion.grupo_id<7">Selecciona el asesor al que deseas monitorear</p>
                    <div v-if="sesion.grupo_id<7" class="d-flex justify-center aling-center">
                        <v-select style="flex:1 0 auto" placeholder="Buscar usuario" :options="asesoresActivos" label="nombre"  v-model="destino_usuario" @input="monitorearUsuario()">
                            <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                                No se encontró un usuario llamado: <em>{{ search }}</em>.
                            </template>
                            <em style="opacity: 0.5;" v-else>Empieza a buscar...</em>
                            </template>
                        </v-select>
                        <button class="btn-sm btn-danger pt-0 ml-2" @click="noMonitorearUsuario()" style="height:28px">Restablecer</button>
                    </div>
                    <p class="mt-3">Introduce el numero del Paciente a buscar:</p>
                    <div class="input-group input-group-sm">
                        <input v-model="buscarNumero" @keypress.enter="verHistorial" type="text" class="form-control" placeholder="Numero de telefono" max="13" maxlength="13" aria-label="" aria-describedby="basic-addon1">
                        <div class="input-group-append">
                            <button @click="verHistorial" class="btn btn-info" type="button"><font-awesome-icon icon="search" /></button>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="input-group">
                            <select v-if="tipo!=4" ref="idSeleccionado" placeholder="Buscar usuario" class="custom-select mt-3">
                                <option disabled selected value="0">Buscar Usuario</option>
                                <option v-for="(recorreAsesores) in asesoresActivos" :key="recorreAsesores.email"  :value="recorreAsesores.id">{{recorreAsesores.nombre}}</option>
                            </select>

                            <div class="input-group-append dropleft mt-3" :class="{'d-none': this.tipo!=1}">
                                <button class="btn btn-secondary dropdown-toggle btnEtiqueta" type="button" id="etiqueta" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <font-awesome-icon icon="tags" :class="{'': tags==0, 'tQueja': tags==1, 'tConsulta': tags==2, 'tCovid': tags==3,'tEcommerce': tags==4, 'tTicket': tags==5, 'tRH': tags==6,'tSpam': tags==10}"></font-awesome-icon>
                                </button>
                                <div class="dropdown-menu col-1 dropLeftTraspaso " aria-labelledby="etiqueta">
                                    <a class="dropdown-item" @click="tags=0"><font-awesome-icon icon="tags" /> Todos</a>
                                    <a class="dropdown-item tQueja" @click="tags=1"><font-awesome-icon icon="tag" class="tQueja" /> Quejas</a>
                                    <a class="dropdown-item tConsulta" @click="tags=2"><font-awesome-icon icon="tag" class="tConsulta" /> Resultados pendientes</a>
                                    <a class="dropdown-item tCovid" @click="tags=3"><font-awesome-icon icon="tag" class="tCovid" /> Estudio COVID-19</a>
                                    <a class="dropdown-item tEcommerce" @click="tags=4"><font-awesome-icon icon="tag" class="tEcommerce" /> Ecommerce</a>
                                    <a class="dropdown-item tTicket" @click="tags=5"><font-awesome-icon icon="tag" class="tTicket" /> Ticket Perdidos</a>
                                    <a class="dropdown-item tRH" @click="tags=6"><font-awesome-icon icon="tag" class="tRH" /> Recursos Humanos</a>
                                    <!--<a class="dropdown-item tlucha" @click="tags=7"><font-awesome-icon icon="tag" class="tlucha" /> Octubre Rosa</a>-->
                                    <a class="dropdown-item tLentes" @click="tags=8"><font-awesome-icon icon="tag" class="tLentes" /> Optica/Lentes</a>
                                    <a class="dropdown-item tCallcenter" @click="tags=9"><font-awesome-icon icon="tag" class="tCallcenter" /> Callcenter</a>
                                    <a class="dropdown-item tCallcenter" @click="tags=10"><font-awesome-icon icon="tag" class="tSpam"/> Spam</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="this.alert2" class="alert alert-danger alert-dismissible fade show m-2 mt-3" role="alert">
                    Seleccionar motivo
                    <button @click="cerrar2" type="button" class="close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <select v-if="this.tipo!=4" ref="seleccionado"  class="custom-select mt-3" :class="{'d-none': this.tipo==2}">
                    <option disabled selected value="0">Motivo</option>
                    <option v-for="(motivo) in motivos" :key="motivo.id"  :value="motivo.id" >{{motivo.descripcion}}</option> 
                </select>
            </div>
            <div class="modal-footer" v-if="tipo!=4">
                <button   type="button" class="btn btn-secondary" data-dismiss="modal" ref="myBtn">Cancelar</button>
                <button type="button" class="btn btn-primary" @click="traspasarPaciente">Traspasar</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import asesorService from '../services/asesor'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTag, faTags } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
library.add(faTag, faTags)
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Querys from '../graphQL/chat.graphql'
export default {
    name: 'TrasferirChat',
    data() {
        return {
            asesores: '',
            selected: '',
            seleccionado:'',
            motivos:'',
            tipo: 0,
            alert1: false,
            alert2: false,
            tags:0,
            destino_usuario:null,
            sesion: JSON.parse(sessionStorage.getItem('sesion')),
            buscarNumero: '521',
            asesoresActivos:[],
            miUsuario:'',
            sessionStorageUser: JSON.parse(sessionStorage.getItem('sesion')),
            idSeleccionado:''
        }
    },
    components:{
        FontAwesomeIcon,
        vSelect
    },
    created() {
        this.$bus.$off('sencillo')
        this.$bus.$on('sencillo', () => this.tipo=0)
        this.$bus.$off('multiple')
        this.$bus.$on('multiple', () => this.tipo=1 )
        this.$bus.$off('abrirInbox')
        this.$bus.$on('abrirInbox', () => this.tipo=2 )
        this.$bus.$off('desdeParametros')
        this.$bus.$on('desdeParametros', () => this.tipo=3 )
        this.$bus.$off('monitorear')
        this.$bus.$on('monitorear', () => this.tipo=4 )
    },
    methods: {
        getSubscriptionAndQuery() {
            if (this.sesion.grupo_id == 8 || this.sesion.grupo_id == 9) {
                return { subscriptionName: 'getPetCTActivos', query: Querys.getPetCTActivos };
            }
            else if (this.sesion.grupo_id == 10) { 
                return { subscriptionName: 'getMesaActivosMesa', query: Querys.getMesaActivosMesa };
            } else if (this.sesion.grupo_id != 6) {
                return { subscriptionName: 'getAsesoresActivos', query: Querys.getAsesoresActivos };
            } else {
                return { subscriptionName: 'getMedicosActivos', query: Querys.getMedicosActivos };
            }
        },
        asesorsActivos(){
            const fechas = asesorService.getFechasHoy()
            
            if(this.$apollo.subscriptions.getAsesoresActivos){
                    this.$apollo.subscriptions.getAsesoresActivos.destroy()
            }
            if(this.$apollo.subscriptions.getMedicosActivos){
                    this.$apollo.subscriptions.getMedicosActivos.destroy()
            }
            if(this.$apollo.subscriptions.getPetCTActivos){
                    this.$apollo.subscriptions.getPetCTActivos.destroy()
            }
            if(this.$apollo.subscriptions.getMesaActivos){
                    this.$apollo.subscriptions.getMesaActivos.destroy()
            }

            const { subscriptionName, query } = this.getSubscriptionAndQuery();
             
            this.$apollo.addSmartSubscription(subscriptionName, {
            query: query,
                variables () {
                    return {
                        id: this.sessionStorageUser.id,
                        fechaI: fechas.fechaI,
                        fechaF: fechas.fechaF
                    }
                },
                async result ({ data }) {
                    this.miUsuario = this.sesion.nombre
                    const asesores = data.asesors
                    this.asesoresActivos=[]
                    asesores.forEach(e => {
                        if(e.nombre===this.miUsuario)
                            e.nombre+='🟢'
                        else if(e.login===true&&e.bitacoraasesores.length > 0)
                            e.nombre+='🔴BREAK'
                        else if(e.login=== true && e.bitacoraasesores.length == 0)
                            e.nombre+='🟢'
                        else
                            e.nombre+='🔴'
                        if(this.sesion.rol_id<=4||this.sesion.id==46){
                            this.asesoresActivos.push(e)
                        }else{
                            if(e.id==1 || e.id==3){
                                this.asesoresActivos.push(e)
                            }
                        }
                        
                    });

                    if(this.sesion.rol_id<=4||this.sesion.id==46){
                        
                        let yo ={
                            id: this.sesion.id,
                            nombre: this.sesion.nombre + '🟢'
                        }
                        let cola = {
                            id: 0,
                            nombre: 'COLA'
                        }
                        this.asesoresActivos.push(yo)
                        this.asesoresActivos.push(cola)
                    }
                },  
            })
        },
        listaAsesores() {
            asesorService.listaDeAsesores().then(respa => {
            
                if(this.sesion.rol_id<=4||this.sesion.id==46){
                let yo ={
                    id: this.sesion.id,
                    nombre: this.sesion.nombre
                }
                let cola = {
                    id: 0,
                    nombre: 'COLA'
                }
                this.asesores = respa
                this.asesores.push(yo)
                this.asesores.push(cola)
                }else{
                
                    this.asesores = respa.filter(obj=>{
                    return obj.id==10 || obj.id==21 || obj.id==25 || obj.id==82 || obj.id==47 || obj.id==46 || obj.id==58 || obj.id==86|| obj.id==97|| obj.id==85|| obj.id==113|| obj.id==99||obj.id==54||obj.id==8||obj.id==103||obj.id==48||obj.id==111
                    //return obj.id!=32 && obj.id!=16 && obj.id!=31 && obj.id!=41 && obj.id!=1 && obj.id!=4 && obj.id!=5
                    // 32: Julio, 16: Monitor, 31: Carlos Morales, 41: Alejandro Urias, 1: Axel, 4: Pedrito, 5: Hever
                    //Se quito a Victoria, y se agregó a Alejandro, Joseline, Karely, Iris y Lizbeth
                })
                }
            })
        },
        cerrar(){
            this.alert1 = false
        },
        cerrar2(){
            this.alert2 = false
        },
        traspasarPaciente() {
            let sender = sessionStorage.getItem('sender_actual')
            this.seleccionado = this.$refs.seleccionado.value
            this.idSeleccionado = this.$refs.idSeleccionado.value
            this.$gtag.event('traspasarPaciente',{
                'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                'event_label': 'Total Traspasos',
                'value': 1
            })
            if (this.idSeleccionado <= 0 && (this.tipo<=1) ) {
                this.alert1 = true
                return
            }

            if ((this.seleccionado <=0)&&(this.tipo<=1) ) {
                this.alert2 = true
                return
            }  

            if (this.tipo==3){
                asesorService.traspasarPacientesParametros(this.idSeleccionado,this.seleccionado).then(()=>{  
                    this.$bus.$emit('terminar-conversacion')
                    const elem = this.$refs.myBtn
                    elem.click() 
                })
            } else if (this.tipo==1){
               if (this.tags!=0){
                   let etiqueta= this.tags==1? "'queja':true": this.tags==2?"'consulta':true": this.tags==3?"'covid':true": this.tags==4?"'ecommerce':true": this.tags==5?"'ticketperdido':true": this.tags==6?"'rh':true": this.tags==7?"'lucha':true":this.tags==8?"'lentes':true":"'callcenter':true"
                   asesorService.traspasarPacientesConEtiqueta(this.idSeleccionado,this.seleccionado, etiqueta).then(()=>{  
                        this.$bus.$emit('terminar-conversacion')
                        const elem = this.$refs.myBtn
                        elem.click() 
                        sessionStorage.removeItem('senderid')
                        sessionStorage.removeItem('sender_actual')
                    })
               } else{
                   asesorService.traspasarPacientesMasivo(this.idSeleccionado,this.seleccionado).then(()=>{  
                        this.$bus.$emit('terminar-conversacion')
                        const elem = this.$refs.myBtn
                        elem.click() 
                        sessionStorage.removeItem('senderid')
                        sessionStorage.removeItem('sender_actual')
                    })
               }
           } else if (this.tipo==0) {
               asesorService.traspasarPaciente(sender,this.idSeleccionado,this.seleccionado).then(()=>{  
                    this.$bus.$emit('terminar-conversacion')
                    const elem = this.$refs.myBtn
                    elem.click()
                    sessionStorage.removeItem('sender_actual')
                })
           } else {

               if(this.idSeleccionado>0){
                   asesorService.traspasarPaciente(sender,this.idSeleccionado,5).then(()=>{    
                        const elem = this.$refs.myBtn
                        elem.click() 
                        
                    })
                    return true
               }else{
                    asesorService.traspasarPacientePlataforma(sender).then(()=>{    
                        const elem = this.$refs.myBtn
                        elem.click() 
                        sessionStorage.setItem('sender','')  
                        return true 
                    })
               } 
                this.alert1 = false
                this.alert2 = false
           }
        },
        listaMotivos() {
            asesorService.listaMotivos().then(mot=>{ 
                this.motivos = JSON.parse(JSON.stringify(mot))  
            })
        },
        monitorearUsuario(){
            sessionStorage.setItem('asesorId', this.destino_usuario.id)
            this.$bus.$emit('actualizar-chat')
        },
        noMonitorearUsuario(){
            this.destino_usuario=null
            let asesor = JSON.parse(sessionStorage.getItem('sesion'))
            sessionStorage.setItem('asesorId', asesor.id)
            this.$bus.$emit('actualizar-chat')
        },
        verHistorial(){
            sessionStorage.setItem('sender_actual', this.buscarNumero)
            this.$bus.$emit('paciente')
            this.$bus.$emit('ver-mensajes', this.buscarNumero)
            this.$bus.$emit('chatAbierto', true)
        }
    },
    mounted() {
        //this.listaAsesores()
        this.listaMotivos()
        this.asesorsActivos()
    },
    
}
</script>
