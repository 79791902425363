<template>
<header>
  <link href='https://fonts.googleapis.com/css?family=Wallpoet' rel='stylesheet'>
  <nav :class="{'navbarAsesor navbar-expand-lg navbar-light container-fluid': asesor, 'navbar navbar-expand-lg navbar-light container-fluid': !asesor}">
    <!-- v-show="sessionStorageUser.rol_id!=3" -->
    <a @click="dashboard" v-if="sessionStorageUser.grupo_id!=7||sessionStorageUser.grupo_id==2" class="navbar-brand align-self-center pointer" title="Ir a Dashboard">

      <img :class="{'logo2': asesor, 'logo': !asesor}"  v-show="network!=4&&network!=6" src="../assets/images/logo.svg" height="50px" alt="La salud es para todos" />
      <img class="logoBlancoY"  v-show="network!=4&&network!=6" src="../assets/images/logoBlanco.svg" height="50px" alt="La salud es para todos" />
      <img v-show="network==4" src="../assets/images/hemo_logo.png" class="logoHemod" alt="Hemodialisis" />
      <img v-show="network==6" src="../assets/images/logoPetCT.svg" class="logoPetct" alt="PetCT" />
    </a>
    <a v-else class="navbar-brand align-self-center" title="Logo Salud Digna">
      <img :class="{'logo2': asesor, 'logo': !asesor}"  v-show="network!=4&&network!=6" src="../assets/images/logo.svg" height="50px" alt="La salud es para todos" />
      <img class="logoBlancoY"  v-show="network!=4&&network!=6" src="../assets/images/logoBlanco.svg" height="50px" alt="La salud es para todos" />
      <img v-show="network==4" src="../assets/images/hemo_logo.png" class="logoHemod" alt="Hemodialisis" />
      <img v-show="network==6" src="../assets/images/logoPetCT.svg" class="logoPetct" alt="PetCT" />
    </a>
    <div v-if="sessionStorageUser.grupo_id!=6 && sessionStorageUser.grupo_id!=8 && sessionStorageUser.grupo_id!=9  && sessionStorageUser.grupo_id!=10" v-show="ruta=='/chat'" class="switch-network row">
      <div class="btn-network rounded-circle" :class="{'bg-whatsapp': network==0}" @click="seleccionarWA">
        <i class="icon-whatsapp opt-wa"></i>
        <img class="bandera" src="../assets/images/mx.svg" height="13px" alt="Bandera de México" /> 
      </div>            
      <div class="btn-network rounded-circle" :class="{'bg-messenger': network==1}" @click="seleccionarFBMX">
        <i class="icon-messenger-new opt-fb"></i>
      </div>
      <div class="btn-network rounded-circle" :class="{'bg-whatsapp': network==3}" @click="seleccionarWAni">
        <i class="icon-whatsapp opt-wa"></i>
        <img class="bandera" src="../assets/images/ni.svg" height="13px" alt="Bandera de Nicaragua" />
      </div>
      <div  class="btn-network rounded-circle" :class="{'bg-hemoSelected': network==4}" @click="seleccionarWAhemo">
        <i class="icon-whatsapp opt-wa"></i>
        <img class="bandera logHemo" src="../assets/images/hemo_isotipo.png" height="30px" alt="Comunidad de hemodialisis" />
      </div>
      
      <div  class="btn-network rounded-circle" :class="{'bg-petCTSelected': network==6}" @click="seleccionarWApetct">
        <i class="icon-whatsapp opt-wa"></i>
        <img class="bandera logHemo" src="../assets/images/petCT.svg" height="30px" alt="PetCT" />
      </div>
      <div class="btn-network rounded-circle" :class="{'bg-whatsapp': network==9}" @click="seleccionarWAgml">
        <i class="icon-whatsapp opt-wa"></i>
        <img class="bandera" src="../assets/images/gml.png" height="16px" alt="Bandera Guatemala" />
      </div>

      <div class="btn-network rounded-circle" :class="{'bg-whatsapp': network==5}" @click="seleccionarWAsa">
        <i class="icon-whatsapp opt-wa"></i>
        <img class="bandera" src="../assets/images/sa.svg" height="22px" alt="Bandera de El Salvador" />
      </div>
    </div>
    
    <div class="w-50">
      <ul class="navbar-nav ">
        <li class="aviso"></li>
        <li class="nav-item dropdown controldeAccion mr-3 d-flex">
          <div><span v-if="breakCerrado==1" class="parpadea texto-naranja" style="position: relative;top: 5px;right: 10px;">Break Activo</span></div>
          
            <div v-if="sessionStorageUser.grupo_id<6" v-show="ruta=='/chat'" class="progress mt-2" style="width:100px;" data-toggle="tooltip" data-placement="bottom" :title="'Atendidos: '+atendidos">
              <div class="progress-bar bg-success" role="progressbar" :style="'width:'+((atendidos)*100)/(meta - noAtendidos)+'px;'" :aria-valuenow="((atendidos)*100)/(meta - noAtendidos)" aria-valuemin="0" :aria-valuemax="100" />
            </div>
            <span v-if="sessionStorageUser.grupo_id<6" v-show="ruta=='/chat'" class="ml-2 mt-2">Meta: {{totalMeta}}</span>
            <avisos v-if="sessionStorageUser.grupo_id<6" />
            <font-awesome-icon v-show="asesor" v-if="mostrarC" class="coment" :icon="['far', 'comment']" @click="irchat" style="cursor: pointer;"/>
            <i class="icon-power" v-show="asesor" v-if="mostrarC" @click="cerrarSesion" style="cursor: pointer; top: 25px;bottom: 47px;font-size: 33px;right: 63px;height: 34px;position: sticky;color:  #616161;opacity: 1; "></i>
            <div v-show="!asesor" id="accordion" role="tablist" v-click-afuera="fueraPerfil">
                <div id="contenedorPerfil" class="borderC">
                  <div @click="dropdown" class="card-header transparente p-0" role="tab" id="headingOne">
                    <h5 class="mb-0" id="menuPerfil">
                      <a  class="nav-link d-flex align-items-center justify-content-end letra16 dropbtn" aria-expanded="true" aria-controls="miPerfil">
                        <img v-if="!status" :src="'https://sd-storage.sfo2.digitaloceanspaces.com/PLATAFORMA-INBOX/imagenes/asesores/'+sessionStorageUser.id+'.jpg'" class="rounded-circle margenMovilL" style="height:25px" />
                        <span v-else class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> &nbsp;
                        <strong class="d-none d-sm-none d-md-none d-lg-block ml-1">{{sessionStorageUser.nombre}}</strong>
                        <i class="icon-angle-down-1" id="toggleFlecha"></i>
                      </a>
                    </h5>
                  </div>

                  <div id="miPerfil" class="collapse dropdown-content" role="tabpanel" aria-labelledby="miPerfil">
                    <div class="card-body bg-perfil p-0">
                      <div v-show="ruta=='/chat'||ruta=='/resultados'" class="switch custom-switch">
                        <input v-model="thema" type="checkbox" class="custom-control-input darkMode" id="darkMode" @click="darkMode" />
                        <label class="custom-control-label h7" for="darkMode">Modo oscuro</label>
                      </div>
                      <!-- <a v-show="ruta=='/chat'||ruta=='/dashboard'" v-if="sessionStorageUser.rol_id<=2" class="dropdown-item border-top-0" @click="resultados"><font-awesome-icon icon="microscope" /> Resultados</a> -->
                      <a v-show="ruta=='/chat'||ruta=='/resultados'" v-if="sessionStorageUser.rol_id<=2&&sessionStorageUser.grupo_id!=7" class="dropdown-item border-top-0" @click="dashboard"><font-awesome-icon icon="home" /> Dashboard</a>
                      <a class="dropdown-item border-top-0" @click="miniPerfil" id="miniPerfil" data-toggle="collapse" aria-expanded="true" aria-controls="perfil">
                        <font-awesome-icon :icon="['fas','address-card']" />&nbsp; Mi perfil
                      </a>    
                        <div v-if="sessionStorageUser.grupo_id<6">
                            <button v-show="ruta=='/chat'||ruta=='/resultados'" type="button" class="dropdown-item border-top-0" data-toggle="modal" data-target="#modalBreak">
                              <i class="icon-clock-1" style="color:red"/> Break
                            </button>  
                        </div>
                        <div v-if="sessionStorageUser.rol_id == (3)|| sessionStorageUser.rol_id == (4)">
                            <button v-show="ruta=='/chat'||ruta=='/resultados'" @click="getNotasAsesor()" type="button" class="dropdown-item border-top-0" data-toggle="modal" data-target="#modalNotas">
                              <font-awesome-icon icon="newspaper" /> Comentarios
                            </button>  
                        </div>
                             <button v-if="sessionStorageUser.grupo_id!=6" @click="tachita()" v-show="ruta=='/chat'||ruta=='/resultados'" type="button" class="dropdown-item border-top-0 navbarFeli" data-toggle="modal" data-target="#modalFelizometro" >
                              <font-awesome-icon icon="smile"/> Felizometro
                            </button>          
                          <div id="perfil" class="collapse" role="tabpanel" aria-labelledby="perfil">
                        <div class="perfilImg text-center">
                          <img :src="'https://sd-storage.sfo2.digitaloceanspaces.com/PLATAFORMA-INBOX/imagenes/asesores/'+sessionStorageUser.id+'.jpg'" class="rounded-circle" />
                        </div>
                        <p>
                          <strong>Nombre:</strong> {{sessionStorageUser.nombre}}
                        </p>
                        <p>
                          <strong>Área:</strong> {{sessionStorageUser.grupo_id==1?'Productos Digitales':sessionStorageUser.grupo_id==2?'Social Media Jr':sessionStorageUser.grupo_id==3?'Marketing Automatizado':sessionStorageUser.grupo_id==4?'Ecommerce':sessionStorageUser.grupo_id==6?'Innovación':sessionStorageUser.grupo_id==7?'Experiencia al Paciente':sessionStorageUser.grupo_id==8?'Pet-CT':sessionStorageUser.grupo_id==9?'Pet-CT':'E-Marketing'}}
                        </p>
                        <p v-if="sessionStorageUser.grupo_id<6">
                          <strong>Progreso:</strong> 
                            <span v-if="atendidos < deberAtendidos"> Te falta {{ deberAtendidos - atendidos }} paciente(s) para estar al día</span>
                            <span v-else-if="atendidos > deberAtendidos"> Llevas {{ atendidos - deberAtendidos }} paciente(s) de ventaja</span>
                            <span v-else> Estas al día</span>
                        </p>
                        <p>
                          <strong>Tipo de usuario:</strong> <span v-if="sessionStorageUser.rol_id == 1">Administrador</span><span v-else-if="sessionStorageUser.rol_id == 2">Moderador</span><span v-else-if="sessionStorageUser.rol_id == 4">Colabolador de apoyo</span><span v-else>Asesor</span>
                        </p>

                      </div>
                      <div v-if="(sessionStorageUser.id ==(13)||sessionStorageUser.id ==(3))" v-show="ruta=='/chat'" class="switch custom-switch">
                        <input type="checkbox" class="custom-control-input darkMode" id="SwitchASESOR" :checked="!checked" @click="cambiarSwitch"/>
                        <label class="custom-control-label h7" for="SwitchASESOR">Activar Asesor</label>
                      </div>
                      <div class="">
                        <a @click="cerrarSesion" class="dropdown-item bUltimo click"><font-awesome-icon icon="sign-out-alt" /> &nbsp;Cerrar sesión</a>
                      </div>

                    </div>
                  </div>
                </div>
            </div>
        </li>
      </ul>
    </div>
    <!-- Modal Felizometro -->
    <div class="modal fade" id="modalFelizometro" role="dialog"  data-backdrop="static" data-keyboard="false" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered " role="document">
        <div class="modal-content modal-xl">
          <div class="modal-header">
            <h4 class="modal-title" >Encuesta Felizometro</h4>
                <div id="cerraModal"></div>
                
          </div>
          
          <div class="modal-body">
            <h5>¿Cómo te encuentras en este momento?</h5>
            <div class="row">
              <ul class="list-group list-group-horizontal" style="font-size:35px">
                
                <div id="radios">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" >
                    <label class="whatever" for="inlineRadio1" @click="idestatus=1"><font-awesome-icon icon="laugh" style="color:#c3e041"/><p>Motivad@</p></label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" >
                    <label class="whatever" for="inlineRadio2" @click="idestatus=2"><font-awesome-icon icon="smile" style="color:#f6ba29 "/><p>Feliz</p></label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3" >
                    <label class="whatever" for="inlineRadio3" @click="idestatus=3"><font-awesome-icon icon="meh" style="color:#dd64d7  "/><p>Normal</p></label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" value="option4" >
                    <label class="whatever" for="inlineRadio4" @click="idestatus=4"><font-awesome-icon icon="sad-cry" style="color:#6b6e70"/><p>Triste</p></label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio5" value="option5" >
                    <label class="whatever" for="inlineRadio5" @click="idestatus=5"><font-awesome-icon icon="tired" style="color:#f7e0af"/><p>Estresad@</p></label>
                  </div>
                </div>
              </ul>
              <div class="input-group col-sm-12">
                      <span class="input-group-text" id="inputGroup">Comentarios</span>
                    <input v-model="fDescripcion" type="text" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default">
                  </div>
            </div> 
          </div >
          <div class="modal-footer">
            <button v-if="idestatus==0" type="button" class="btn btn-primary" disabled>Guardar</button>
              <button v-else type="button" class="btn btn-primary" @click="envioFelizometro(sessionStorageUser.id,idestatus,fDescripcion,entradaTurno)">Guardar</button>
          </div>
        </div>
      </div>
    </div>
<!-- Termina Modal Felizometro -->
<!-- Modal Break -->
<div class="modal fade" id="modalBreak" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style="height: 65vw;">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLongTitle">Break</h2>
        <button type="button" class="close" @click="cerrarModalBreak()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="margin-top: -15px;">
        <div style="padding:1rem">
          <div class="row">
            <div class="list-group list-group-horizontal">
              <h4 style="margin-top: 5px;">Seleccione un motivo: </h4>
                <select v-if="this.running"  class="custom-select" v-model="motivoSeleccionado" disabled  style="max-width: 305px;margin-left: 5px">
                  <option v-for="motivo in motivos" v-bind:key="motivo.id" :value="motivo.id">
                    
                  {{motivo.motivo.toUpperCase()}} - {{motivo.descripcion}} - {{motivo.tiempo}} 
                  </option>
                </select>
                <select v-else class="custom-select" v-model="motivoSeleccionado" style="max-width: 305px;margin-left: 5px">
                  <option v-for="motivo in motivos" v-bind:key="motivo.id" :value="motivo.id">
                    {{motivo.motivo.toUpperCase()}} - {{motivo.descripcion}} - {{motivo.tiempo}} 
                  </option>
                </select>
                </div>
            </div>
          </div>
            <div id="clock"  style="text-align:center ;">
              <div class="time">{{ time }}</div>
              
            </div>
            
      </div>
      <div class="modal-footer">
        <button v-if="this.running" type="button" class="btn btn-secondary" id="detener"  @click="cerrarBreak()">Detener</button>
        <button v-else type="button" class="btn btn-secondary" id="detener" data-dismiss="modal">Cerrar</button>
        <button v-if="this.botonac==1 || !this.motivoSeleccionado" type="button" class="btn btn-primary" id="aceptar" disabled>Aceptar</button>
        <button v-else type="button" class="btn btn-primary" id="aceptar" @click="activarBreak(motivoSeleccionado)" >Aceptar</button>
      </div>
    </div>
  </div>
</div>
<!-- Termina modal Break -->
<!-- Modal Notas -->
  <div class="modal fade" id="modalNotas" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" style="top: 10%; width:70%; left:17%;max-height: none !important; z-index:999;overflow-y: auto;" >
      <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content" style="transform: translateY(0%); ">
          <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Comentarios Plataforma</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="contenido-modal">
                  <tbody>
                      <DataTable :value="Notas" ref="conversaciones" @row-select="onRowSelect" :filters="filters" :paginator="true" :loading="status" :rows="8" :selection.sync="seleccionadonota" selectionMode="single"  data-key="senderid"  class="p-datatable-responsive">
                        <template #loading>
                            <span class="loading-text">Hola</span>
                        </template>
                        <Column field="telefono" header="Teléfono" :sortable="true" :default-value="'No hay notas'"></Column>
                        <Column field="calificacion" header="Calificación" :sortable="true"></Column>
                        <Column field="desmotivo" header="Motivo" :sortable="true"></Column>
                        <Column field="descripcion" header="Comentario" :sortable="true"></Column>
                    </DataTable>
                  </tbody>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
          </div>
          </div>
      </div>
  </div>
        
  </nav>
</header>
</template>

<style>
.navbarAsesor{
  height: 101px;
background: #FFFFFF 0% 0% no-repeat padding-box;
opacity: 1;
width: 100%;
right: 0;
top:0.3220062255859375px;
position: fixed;
z-index: 999;
}
.coment{
top: 34px;
bottom: 47px;
font-size: 38.15px;
right: 120px;
height: 34px;
position: sticky;
color:  #616161;
opacity: 1; 
}
.logo2{
  top: 19px;
left: 49px;
width: 299px;
height: 62px;
position: absolute;
}
.click {
    cursor: pointer;
}
.list-group{
  margin:15px;
  
}
.dark-mode .custom-control-label a{
    color: white;
  }
  .dark-mode .modal-title {
  color: white;
}
.custom-control-label a{
    font-size: large;
  }
.whatever{
    display: inline-block;
    width: 95px;
    height: 85px;
    font-size:xx-large;
    text-align:center;   
}

#radios input[type=radio]{
    display: none;
}

#radios input[type=radio]:checked + .whatever{
    background: #d4ebff;
    border-style: solid;
    border-width: 1px;
    border-radius: 25px;
    border-color: #0074d9;
}

.dark-mode #radios input[type=radio]:checked + .whatever{
    background: #696a6b;
    border-style: solid;
    border-radius: 25px;
    border-width: 1px;
    border-color: #28a745;
}
.dark-mode .whatever{
  color: white;
}
.dark-mode .navbarFeli{
  color: #c5c5c5;
}
.modal-xl{
  width :600px;
  
}
.time {
    font-size: 6em;
    font-family: Wallpoet;
  }
  .p-dialog.p-component.conversaciones{
  width: 75%;
}  
.chatActive .bg-google {
    background-color:#4285F5; }
    .switch-network .bg-google {
    background-color:#4285F5;
    color: white;
    animation-name: tresS;
    animation-duration: .3s; }    
.opt-go{
font-size: 25px;
position: relative;
bottom: -6px;
left: 8px;
}
</style>
<script>
//import SocketIo from 'socket.io-client'
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Conversaciones from "./Dashboard/Tablas/Conversaciones.vue";
import Avisos from './Avisos.vue'
import asesorService from '../services/asesor'
import controlesServices from '../services/controles'
import { library } from '@fortawesome/fontawesome-svg-core'
import {faUserCircle, faAddressCard, faSignOutAlt, faHome, faBell, faPaperclip, faMicroscope,faSmile, faAngry,faLaugh,faFrown,faMeh,faHeadSideMask,faKissBeam,faSadCry,faDizzy,faTired,faEye } from '@fortawesome/free-solid-svg-icons'
import { faBell as Campana, faHandPaper, faChartBar, faHospital, faGrinStars,faComment } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Querys from '../graphQL/chat.graphql'
const moment = require('moment')
library.add(faComment,faUserCircle, faAddressCard, faSignOutAlt, faHome, faBell, Campana,faHandPaper, faChartBar, faHospital, faGrinStars, faPaperclip, faMicroscope,faSmile,faAngry,faLaugh,faFrown,faMeh,faHeadSideMask,faKissBeam,faSadCry,faDizzy,faTired,faEye )

export default {
  
    name: "Navbar",
    data() {
        return {
            cambiarFlecha: false,
            cambiarMenu: false,
            sessionStorageUser: JSON.parse(sessionStorage.getItem('sesion')),
            ruta: window.location.pathname,
            status: false,
            checked:false,
            switch:false,
            tema: JSON.parse(localStorage.getItem('tema')),
            thema:null,
            atendidos: 0,
            network: 0,
            showCampana:false,
            notifications:null,
            felizometro:{"asesor_id": 0,"estatus": 0,"descripcion": "","entradaTurno": 0},
            tab:1,
            entradaTurno:0,
            fDescripcion:null,
            cerraModal:'',
            idestatus:0,
            motivos:null,
            motivoSeleccionado:null,
            time: "00:00:00",
            timeBegan : null,
            timeStopped : null,
            stoppedDuration : 0,
            started : null,
            running : false,
            estatusBreak: null,
            randomT:null,
            botonac:0,
            meta: 0,
            metaphora: 0,
            deberAtendidos: 0,
            horario: {},
            noAtendidos: 0,
            totalMeta: 0,
            fecha_inicio: null,
            fecha_final: null,
            Notas:[{"id": null,"motivo_id": null,"asesor_id":null,"monitor_id":null,"telefono": "No","calificacion": "hay","descripcion": "disponibles","estatus": null,"desmotivo": "comentarios","nombre_asesor": null,"nombre_monitor": null}],
            filters:{},
            telefono:null,
            calificacion:null,
            motivo:null,
            comentario:null,
            nombre:null,
            monitor:null,
            chat: false,
            seleccionadonota:null,
            id:null,
            breakCerrado:0,
            asesor:false,
            mostrarC:false
        };
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon, Avisos/* ,Dialog,Mensajes */,DataTable: DataTable,
        Column: Column/* vSelect */
    },
  created() {
      
      const { grupo_id } = this.sessionStorageUser;

      if (grupo_id === 8 || grupo_id === 9) {
          localStorage.setItem('Network', 'WApetct');
        } else if (grupo_id === 10) {
            localStorage.setItem('Network', 'WAmesa');
        } else if (grupo_id !== 6) {
            localStorage.setItem('Network', 'WA');
        } else {
            localStorage.setItem('Network', 'WAmedicos');
      } 
      
        if(this.ruta=='/chat'){
            if(this.tema){
            document.documentElement.classList.toggle('dark-mode')
            this.thema=true
            }else{
                this.thema=false
            }
        }
        if(this.sessionStorageUser.rol_id==3){
          if(window.location.href.includes('/inicio')){
            this.asesor=true
          }
            
        }
        if(localStorage.getItem('Network')=='WA'){
          this.network=0
          this.seleccionarWA()
        } else if(localStorage.getItem('Network')=='FB'){
          this.network=1
          this.seleccionarFBMX()
        } else if(localStorage.getItem('Network')=='WAni'){
          this.network=3
          this.seleccionarWAni()
        } else if(localStorage.getItem('Network')=='WAhemo'){
          this.network=4
          this.seleccionarWAhemo()
        } else if(localStorage.getItem('Network')=='WAmedicos'){
          this.network=5
          this.seleccionarWAmedicos()
        } else if(localStorage.getItem('Network')=='WApetct'){
          this.network=6
          this.seleccionarWApetct()
        } else if(localStorage.getItem('Network')=='WAmesa'){
          this.network=7
          this.seleccionarWAmesa()
        } else{
            this.network=0
            this.seleccionarWA()
        }
        this.$bus.$off('updateHistorial')
        this.$bus.$on('updateHistorial', () => {
            this.status=true
            Conversaciones.cargarHistorialChats()
        })
        this.calcularMeta()
        this.getAtendidos()
    },
    mounted(){
     this.getMotivos()
     this.getNotasAsesor()
     this.$on('cerrar', this.getNotasAsesor());
     this.getEstatusBreak()
        if((this.sessionStorageUser.id ==(9)||this.sessionStorageUser.id ==(13)||this.sessionStorageUser.id ==(11)||this.sessionStorageUser.id ==(10)||this.sessionStorageUser.id ==(3))){this.get()}
        if(this.sessionStorageUser.rol_id==3){
          this.primerEstatus()
          }
    },
    directives: {
        "click-afuera": {
        bind: function(el, binding) {
            // Define ourClickEventHandler
            const ourClickEventHandler = event => {
            if (!el.contains(event.target) && el !== event.target) {
                // as we are attaching an click event listern to the document (below)
                // ensure the events target is outside the element or a child of it
                binding.value(event); // before binding it
            }
            };
            // attached the handler to the element so we can remove it later easily
            el._vueClickEventHandler_ = ourClickEventHandler;

            // attaching ourClickEventHandler to a listener on the document here
            document.addEventListener("click", ourClickEventHandler);
        },
        unbind: function(el) {
            // Remove Event Listeners
            document.removeEventListener("click", el._vueClickEventHandler_);
        }
        }
    },
    apollo: {
        $subscribe: {}
    },
    watch: {
        meta: {
          async handler() {
            await this.getBreaksEspeciales()
          }
        }
      },
    methods: {
      enviarDato() {
      const dato = this.totalMeta
      this.$emit('dato-enviado-al-padre', dato);
    },
        darkMode(){
            document.documentElement.classList.toggle('dark-mode')
            localStorage.setItem('tema', JSON.parse(!this.thema))
        },
        fueraPerfil(){
            var div = document.getElementById("miPerfil")
            var perfil = document.getElementById("perfil")
            var menuPerfil = document.getElementById("menuPerfil")
            var flecha = document.getElementById("toggleFlecha")
            
            
            
            if (perfil.classList.contains('show')) {
                perfil.classList.remove('show')
                menuPerfil.classList.toggle("menuPerfil")
            }
            
                div.classList.remove('show')
                flecha.classList.remove("icon-angle-up-1")
                flecha.classList.add("icon-angle-down-1")
            
        },
        toggled() {
            var flecha = document.getElementById("toggleFlecha");
            flecha.classList.toggle("icon-angle-up-1");
            flecha.classList.toggle("icon-angle-down-1");
            var menuPerfil = document.getElementById("menuPerfil");
            var perfil = document.getElementById("perfil");
            if (perfil.classList.contains("show")) {
                menuPerfil.classList.toggle("menuPerfil");
                perfil.classList.remove("show");
            }
        },
        miniPerfil() {
            this.calcularDeberAtendidos()
            var menuPerfil = document.getElementById("menuPerfil");
            var perfil = document.getElementById("perfil");
            perfil.classList.toggle("show");
            menuPerfil.classList.toggle("menuPerfil");
            this.$gtag.event('verMeta',{
                'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                'event_label': 'Veces que se quizo ver la meta',
                'value': 1
            })
        },
        dropdown() {
            document.getElementById("miPerfil").classList.toggle("show");
            this.toggled();
        },
        cerrarSesion() {
            this.status=true
            asesorService.cerrarSesion().then(()=>{
                sessionStorage.clear()
                window.location.href = '/'
                this.status=false
            }).catch(()=>{
                sessionStorage.clear()
                window.location.href = '/'
                this.status=false
                this.$gtag.event('errores',{
                    'event_category': 'error',
                    'event_label': 'cerrarSesion',
                    'value': 500
                })
            })

        },
        dashboard() {
          if(this.sessionStorageUser.rol_id==3&&this.sessionStorageUser.grupo_id==2){
            window.location.href = '/inicio';
          }else{
            window.location.href = this.sessionStorageUser.grupo_id!=6?'/dashboard':'/dashboard/parametros'
          }
            
            
        },
        resultados() {
            window.location.href = '/resultados'
        },
        get(){
            controlesServices.Control().then(resp => {
                resp.map(x => {
                    if(x.id==1){
                        this.switch=x
                    } else{
                        return
                    }
                    return x
                })
                
                if(this.switch.status==0){
                    this.checked=false
                }else{
                    this.checked=true
                }
            })
        },
        cambiarSwitch(){
            let variable
            variable = this.checked ? 0:1
            controlesServices.CambiarControl(1, variable).then(() => {
                this.get()
            })
        },
        getAtendidos(){
         /* let data = {
            query: {
              "Plataforma_asesor_id": this.sessionStorageUser.id? this.sessionStorageUser.id : 1
            }
          }

          this.socket = SocketIo.connect('https://socket-ws.salud-digna.site', data);
          this.socket.on('getMetaAsesor', (resp) => { 
            if (resp[0] && resp[0].count !== null) {
              this.atendidos = resp[0].count;
              
            }
          });

          if(localStorage.getItem('Network')=='WAmesa'){
            this.atendidos = 0
          }else{
            this.socket.on('getMetaAsesor', (resp) => { 
            const count = resp[0].count;
            this.atendidos = (count !== null) ? count : 0;
          });
          }
       */
       const fechas = asesorService.getFechasHoy() 
      if(this.$apollo.subscriptions.getPacientesAtendidos){
              this.$apollo.subscriptions.getPacientesAtendidos.destroy()
      }
      this.$apollo.addSmartSubscription('getPacientesAtendidos', {
        query: Querys.getPacientesAtendidos,
              variables () {
                  return {
                      id: this.sessionStorageUser.id,
                      fecha_ini: fechas.fechaI,
                      fecha_fin: fechas.fechaF
                  }
              },
              async result ({ data }) {  
                this.atendidos = data.pacientesAtendidosByAsesor[0].total
             },
            })

        },
        getBreaksEspeciales(){
          moment.tz.setDefault("America/Mazatlan");
          const fechas = asesorService.getFechasHoy()
          if(this.$apollo.subscriptions.getBreaksEspeciales){
                  this.$apollo.subscriptions.getBreaksEspeciales.destroy()
          }
          this.$apollo.addSmartSubscription('getBreaksEspeciales', {
              query: Querys.getBreaksEspeciales,
              variables () {
                  return {
                      id: this.sessionStorageUser.id,
                      fechaI: fechas.fechaI,
                      fechaF: fechas.fechaF
                  }
              },
              async result ({ data }) {
                const breaks = data.bitacoraasesores
                const tiempoBreaks = moment(`1900-01-01 00:00:00`)
                breaks.forEach(e => {
                  if(e.motivo_id != 18){
                    let tiempoBreak
                    if(e.status == 0)
                      tiempoBreak = moment(e.desde).preciseDiff(moment(e.hasta), true)
                    else
                      tiempoBreak = moment(e.desde).preciseDiff(moment(), true)
                    tiempoBreaks.add(tiempoBreak.hours, 'hours')
                    tiempoBreaks.add(tiempoBreak.minutes, 'minutes')
                    tiempoBreaks.add(tiempoBreak.seconds, 'seconds')
                  }
                });
                const horas = tiempoBreaks.hours()
                const minutos = tiempoBreaks.minutes()
                const segundos = tiempoBreaks.seconds()
                const metaphoras = Math.round(this.metaphora*horas)
                const metapmins = Math.round((this.metaphora/60)*minutos)
                const metapsegs = Math.round(((this.metaphora/60)/60)*segundos) 
                this.noAtendidos = metaphoras + metapmins + metapsegs
                this.totalMeta = this.meta - this.noAtendidos
                this.enviarDato()
              },
          })
        },
        cambiarNetwork(){
            if(this.network){
                localStorage.setItem('Network', 'WA')
            } else {
                localStorage.setItem('Network', 'FB')
            }
        },
        seleccionarWA(){
          if(this.network != 0){
            localStorage.setItem('Network', 'WA')
            this.network=0
            console.log('WHATSAPP selected')
            asesorService.actualizarNetwork('WA')
            this.seleccionado()
          }
        },
        seleccionarWAni(){
          if(this.network != 3){
            localStorage.setItem('Network', 'WAni')
            this.network=3
            console.log('WHATSAPPNI selected')
            asesorService.actualizarNetwork('WAni')
            this.seleccionado()
          }
        },
        seleccionarWAsa(){
          if(this.network != 5){
            localStorage.setItem('Network', 'WAsa')
            this.network=5
            console.log('WHATSAPPSA selected')
            asesorService.actualizarNetwork('WAsa')
            this.seleccionado()
          }
        },
        seleccionarWAgml(){
          if(this.network != 9){
            localStorage.setItem('Network', 'WAgml')
            this.network=9
            console.log('WHATSAPPGML selected')
            asesorService.actualizarNetwork('WAgml')
            this.seleccionado()
          }
        },
        seleccionarWAhemo(){
          if(this.network != 4){
            localStorage.setItem('Network', 'WAhemo')
            this.network=4
            console.log('Hemodialisis selected')
            asesorService.actualizarNetwork('WAhemo')
            this.seleccionado()
          }
        },
        seleccionarWApetct(){
            localStorage.setItem('Network', 'WApetct')
            this.network=6
            console.log('PetCT selected')
            asesorService.actualizarNetwork('WApetct')
            this.seleccionado()
        },
        seleccionarWAmesa(){
            localStorage.setItem('Network', 'WAmesa')
            this.network=7
            console.log('MesaAyuda selected')
            asesorService.actualizarNetwork('WAmesa')
            this.seleccionado()
        },     
        seleccionarWAmedicos(){
          if(this.network != 5){
            localStorage.setItem('Network', 'WAmedicos')
            this.network=4
            console.log('Medicos selected')
            asesorService.actualizarNetwork('WAmedicos')
            this.seleccionado()
          }
        },
        seleccionarFBMX(){
          if(this.network != 1){
            localStorage.setItem('Network', 'FB')
            this.network=1
            console.log('Facebook selected')
            asesorService.actualizarNetwork('FB')
            this.seleccionado()
          }
        },
        seleccionarGoogle(){
          if(this.network != 1){
            localStorage.setItem('Network', 'GOOGLE')
            this.network=8
            console.log('Google selected')
            asesorService.actualizarNetwork('GOOGLE')
            this.seleccionado()
          }
        },
        seleccionarFBNI(){
            localStorage.setItem('Network', 'FBni')
            this.network=2
            console.log('Nicaragua')
            asesorService.actualizarNetwork('FBni')
            this.seleccionado()
        },
        seleccionado(){
            // this.$bus.$emit('pedirListaChats')
            this.$bus.$emit('actualizar-chat')
            sessionStorage.removeItem('sender')
            sessionStorage.removeItem('sender_actual')
            this.$bus.$emit('cambioNetwork')
            this.$bus.$emit('actualizarNetwork-herramientas')
        },
       async envioFelizometro(a,b,c,d){
         const res = await asesorService.estatusFelizometro(a,b,c,d)
              if(!res.status){
              alert('Error al enviar la encuesta, intentelo nuevamente')  
              return  
              }
          this.entradaTurno=0
          this.felizometro={"asesor_id": a,"estatus": b,"descripcion": c,"entradaTurno":d }                 
          this.cerrarModal()
        },
        cerrarModal () {
        document.querySelector('[data-target="#modalFelizometro"]').click(close)
        },
        tachita(){
          let div1= document.getElementById("cerraModal")
                  if(this.entradaTurno==0){
                    div1.innerHTML=''
                    let button = document.createElement('button')
                    let span = document.createElement('span') 
                        button.setAttribute('type','button')
                        button.setAttribute('class','close')
                        button.setAttribute('aria-label','Close')
                        button.setAttribute('data-dismiss','modal')
                        span.setAttribute('aria-hidden','true')
                        span.innerHTML='&times;'
                        button.appendChild(span)
                        div1.appendChild(button)
                  }           
        },
        getMotivos(){
            asesorService.getMotivos().then(resp => {
              resp.forEach(e => {
                if(e.tiempo==0){
                  e.tiempo="Tiempo indeterminado"
                }else{
                  e.tiempo=e.tiempo+" minutos"
                }
              });
                this.motivos = resp
            })
        },
        start() {
          if (this.running) return;
          if (this.timeBegan === null) {
            this.reset();
            this.timeBegan = new Date(); /* "2022-10-21T15:51:00" */
          }
          this.started = setInterval(this.clockRunning, 10);
          this.running = true;
        },
        reset() {
          this.running = false;
          clearInterval(this.started);
          this.stoppedDuration = 0;
          this.timeBegan = null;
          this.timeStopped = null;
          this.time = "00:00:00";
        },
        clockRunning() {
          var currentTime = new Date(),
            timeElapsed = new Date(currentTime - this.timeBegan - this.stoppedDuration),
            hour = timeElapsed.getUTCHours(),
            min = timeElapsed.getUTCMinutes(),
            sec = timeElapsed.getUTCSeconds()
            this.time =
            this.zeroPrefix(hour, 2) +
            ":" +
            this.zeroPrefix(min, 2) +
            ":" +
            this.zeroPrefix(sec, 2) 
        },
        zeroPrefix(num, digit) {
          var zero = "";
          for (var i = 0; i < digit; i++) {
            zero += "0";
          }
          return (zero + num).slice(-digit);
        },
        async activarBreak(a){
          this.botonac = 1 
          const resp= await asesorService.activarBreak(a)
          if(resp.response=="La automatizacion no se apago correctamente"){
            alert('La automatizacion no se apago correctamente, contacta con tu supervisor para resivir apoyo')
          }if(resp.response==false){
            alert('El Break no se creo de manera correcta, vuelva a intentarlo')
            return
          }
          if(resp.response=="Break activo"){
            alert('Ya cuentas con un Break activo, recarga la pagina para mostrar el cronometro')
          }
          this.start()
          this.$forceUpdate()
        },
        async cerrarBreak(){
          const resp= await asesorService.cerrarBreak()
          if(resp.response=="La automatizacion no se encendio correctamente"){
            alert('La automatizacion no se encendio correctamente, contacta con tu supervisor para resivir apoyo')
          }if(resp.response==false){
            alert('El Break no se cerró de manera correcta, vuelva a intentarlo')
            return
          }
          this.estatusComida()
          this.cerrarModalBreak()
          this.timeBegan=null
          this.running = false;
          this.timeStopped = null;
          this.stoppedDuration = 0;
          clearInterval(this.started);
          setTimeout(() => {
            this.time = "00:00:00";
            this.motivoSeleccionado=null;
          }, 1000)
          /* clearTimeout(this.randomT) */
          this.estatusBreak=null
          this.botonac=0
          this.$forceUpdate()
          
        },
        getEstatusBreak(){
          asesorService.getEstatusBreak().then(resp =>{
              this.estatusBreak = resp
              if(this.estatusBreak.length>0){
                this.breakCerrado=0
                this.motivoSeleccionado=resp[0].motivo_id
                    document.querySelector('[data-target="#modalBreak"]').click();
                    this.botonac=1
                    this.start()
                      this.timeBegan = new Date(this.estatusBreak[0].desde.slice(0,19));
                      this.timeBegan.setHours(this.timeBegan.getHours()+23);
                      //this.timeBegan=moment(this.timeBegan).tz("America/Mazatlan")
                } 
                this.$forceUpdate()
              })
        },
        cerrarModalBreak() {
          asesorService.getEstatusBreak().then(resp =>{
              this.estatusBreak = resp
              if(this.estatusBreak.length>0){
                this.breakCerrado=1
              } else{
                this.breakCerrado=0
              }
                
          })
          
        document.querySelector('[data-target="#modalBreak"]').click(close)
        
        },
        primerEstatus(){
          asesorService.getFelizometro().then(resp => {
          let asesorEncontrado = resp.find(asesor => asesor.asesor_id === this.sessionStorageUser.id);
          if (asesorEncontrado) {
            this.mostrarC=true
          }
        })
        },
        estatusComida(){
                if(this.motivoSeleccionado==1){
                  this.entradaTurno=1
                  document.querySelector('[data-target="#modalFelizometro"]').click();
                }
        },
        calcularMeta(){
          if(this.$apollo.subscriptions.getHorarioMeta){
                  this.$apollo.subscriptions.getHorarioMeta.destroy()
          }
          this.$apollo.addSmartSubscription('getHorarioMeta', {
              query: Querys.getHorarioMeta,
              variables () {
                  return {
                      id: this.sessionStorageUser.id,
                  }
              },
              async result ({ data }) {
                this.horario = data.asesors[0].horario
                this.metaphora = data.asesors[0].meta_hora
                const horario = data.asesors[0].horario[asesorService.getDia()]
                if(!horario.status){
                  this.meta = 0
                  return
                }
                this.meta = (asesorService.calcularMeta({...horario, metaphora: data.asesors[0].meta_hora}))
              },
          })
        },
        async calcularDeberAtendidos(){
          const horario = this.horario[asesorService.getDia()]
          if(!horario.status){
            this.deberAtendidos = 0
            return
          }
          this.deberAtendidos = await asesorService.calcularDeberAtendidos({...horario, metaphora: this.metaphora})
        },
        getNotasAsesor(){
          let dato={
            asesorid:this.sessionStorageUser.id
          } 
          asesorService.getNota(dato).then(resp => {
            if(resp.length>0){
              this.Notas = resp;
              this.telefono = this.seleccionadonota.telefono;
              this.calificacion=this.Notas.map(e => e.calificacion);
              this.motivo = this.Notas.map(e => e.desmotivo);
              this.comentario = this.Notas.map(e => e.descripcion);
              this.nombre=this.Notas.map(e => e.nombre_asesor);
              this.monitor=this.Notas.map(e => e.nombre_monitor);
            }this.Notas=[{"id": null,"motivo_id": null,"asesor_id":null,"monitor_id":null,"telefono": "No","calificacion": "hay","descripcion": "disponibles","estatus": null,"desmotivo": "comentarios","nombre_asesor": null,"nombre_monitor": null}]
            this.onRowUnselect()
          });
        },
        onRowSelect(event) {
            if(this.seleccionado.id!=-1){
              this.seleccionadonota = event.data;
              this.id=this.seleccionadonota.id;
              this.telefono = this.seleccionadonota.telefono;
              this.motivo = this.seleccionadonota.desmotivo;
              this.monitor = this.seleccionadonota.nombre_monitor;
                this.chat = true; 
                this.monitorear()
                sessionStorage.setItem('sender_actual',this.telefono)  
                this.$bus.$emit("abrirConversacion")
                this.$bus.$emit('ver-mensajes', this.telefono)
                this.$bus.$emit('chatAbierto', true)
            }else{
              this.onRowUnselect()
            }
        },
        onRowUnselect() {
            this.seleccionadonota = null;
        },
        cerrar() {
            this.chat = false;
            this.seleccionadonota = null;
            this.getNotasAsesor()
            this.onRowUnselect()
            this.Notas=[]
        },
        cerrarNota(){
          let data={
            id:this.id
          }
          asesorService.cerrarNota(data)
          this.cerrar()
          const indice = this.Notas.findIndex(item => item === this.id);
          if (indice !== -1) {
            this.Notas.splice(indice, 1); // Eliminar el elemento del arreglo
          }
        },
        monitorear(){
            this.$bus.$emit('monitorear', 4);
            document.querySelector('[data-target="#modalNotas"]').click(close)
        },
        irchat(){
          window.location.href = '/chat'
        }
    }
};
</script>